import React from 'react';
import { Link } from 'gatsby';

const Logo = () => {
	return (
		<div className="logo">
			<Link to="/">
				<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.18 237.56">
				<g>
					<path d="M105.83,1.52l-.18-.13h-1.09l-.22.15a86.84,86.84,0,0,1-7.92,4.89c-31.19,18-62.28,36.16-93.51,54.09-.2,36.89,0,73.79-.1,110.68,0,2.88.06,5.76.1,8.64,29.69,17,59.26,34.3,88.91,51.43,4.26,2.6,8.59,4.58,12.51,7.68h1.14c3.87-2.61,8-4.88,12.09-7.24,29.75-17.3,59.77-34.48,89.43-51.88V60.44L118.71,9.31C114.38,6.72,109.9,4.49,105.83,1.52Zm88.49,170.41c-29.77,17.2-59.49,34.51-89.29,51.66q-44.58-25.92-89.24-51.72V68.48Q56.91,44.56,98.1,20.77c2.28-1.33,4.58-2.75,6.94-3.89,3.32,1.59,6.47,3.62,9.67,5.44q39.81,23,79.6,46.09.12,51.77,0,103.52Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					<g>
					<path d="M53.7,107.68a7.32,7.32,0,0,1,2.8,2.09,9.24,9.24,0,0,1,1.5,2.51,8.34,8.34,0,0,1,.55,3.14,8.87,8.87,0,0,1-1.07,4.18,6,6,0,0,1-3.55,2.91,5.58,5.58,0,0,1,2.93,2.36,9.76,9.76,0,0,1,.86,4.67v2a15.05,15.05,0,0,0,.17,2.78A2.49,2.49,0,0,0,59,136v.75H52.15c-.19-.66-.32-1.2-.4-1.6a14.66,14.66,0,0,1-.27-2.58l0-2.77c0-1.91-.35-3.18-1-3.81s-1.82-1-3.56-1H40.8v11.71H34.7V106.91H49A13.05,13.05,0,0,1,53.7,107.68ZM40.8,112.1v8h6.72a7,7,0,0,0,3-.49,3.34,3.34,0,0,0,1.77-3.36c0-1.81-.57-3-1.71-3.65a6.21,6.21,0,0,0-2.89-.52Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					<path d="M117.88,106.64H127v30.23h-5.89V116.42c0-.59,0-1.41,0-2.47s0-1.88,0-2.45l-5.73,25.37h-6.14l-5.68-25.37c0,.57,0,1.39,0,2.45s0,1.88,0,2.47v20.45H97.72V106.64h9.19l5.51,23.77Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					<path d="M145.91,115a9.14,9.14,0,0,1,3.86,3.3,11.05,11.05,0,0,1,1.78,4.62,28.23,28.23,0,0,1,.19,4.44H135.46c.09,2.24.87,3.82,2.34,4.72a5.94,5.94,0,0,0,3.22.84,4.93,4.93,0,0,0,3.25-1,5,5,0,0,0,1.2-1.53h6a7.33,7.33,0,0,1-2.17,4q-3,3.25-8.41,3.26a12.2,12.2,0,0,1-7.87-2.75q-3.42-2.76-3.41-8.95c0-3.88,1-6.84,3.08-8.91a10.74,10.74,0,0,1,8-3.1A12.73,12.73,0,0,1,145.91,115Zm-8.75,5a6.07,6.07,0,0,0-1.55,3.46h10.06a4.87,4.87,0,0,0-1.55-3.54,5.14,5.14,0,0,0-3.47-1.2A4.61,4.61,0,0,0,137.16,120Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					<path d="M167.55,114.87a7.12,7.12,0,0,1,2.56,2.47V106.68H176v30.19h-5.68v-3.1a8.13,8.13,0,0,1-2.85,2.89,8,8,0,0,1-4,.9,8.3,8.3,0,0,1-6.59-3.16,12.17,12.17,0,0,1-2.68-8.14q0-5.72,2.64-9a8.55,8.55,0,0,1,7-3.28A7.24,7.24,0,0,1,167.55,114.87ZM169,130.8a8.18,8.18,0,0,0,1.29-4.78c0-2.74-.69-4.69-2.07-5.87a4.46,4.46,0,0,0-3-1.06,4.1,4.1,0,0,0-3.76,1.94,10.44,10.44,0,0,0,0,9.76,4.15,4.15,0,0,0,3.7,1.85A4.32,4.32,0,0,0,169,130.8Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					</g>
					<path d="M75.47,84l5.83.07-1.91,81s-.13,5.13-1.42,7.58Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					<g>
					<path d="M75.88,142.89l-.79-.36A4,4,0,0,1,72.71,139c0-1.65,1.38-3,3-4l-.13-4.81c-3.2,1.61-6.84,4.31-6.84,8.26,0,0-.32,5.86,7.29,7.84Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					<path d="M76.33,158.78c-2.11,1.42-4.58,3.59-5.26,6.35l.71-.58a12.15,12.15,0,0,1,4.62-3.46Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					<path d="M75.1,115.21l-.15-5.5a6.82,6.82,0,0,1-4.14-6.31c0-3.76,2.06-5.41,3.79-6.13l-.16-5.43c-4.68,1.55-9.41,4.68-9.41,11,0,0-.68,10,9.68,12.26Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					<path d="M82.6,145.94l-1.49-.68-.07,2.9c1.54.91,3,2.23,3,4.05,0,0,.28,2.22-3.15,4.14l-.08,3.34c6.28-2.08,6-7.31,6-7.31A7.13,7.13,0,0,0,82.6,145.94Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					<path d="M81.89,112.31,81.75,118a6.21,6.21,0,0,1,2.79,5,5.3,5.3,0,0,1-3,4.78l-.11,4.79c8.26-3.22,7.79-10.08,7.79-10.08C89.2,117.35,85.4,114.16,81.89,112.31Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					<path d="M83.55,90.33s-.43,0-1.14,0l-.16,6.72a33.82,33.82,0,0,1,3.93,1.06c3.72,1.38,9.32.06,9.32.06S95.5,90.33,83.55,90.33Zm6.12,4.25a.74.74,0,1,1,.74-.74A.74.74,0,0,1,89.67,94.58Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
					</g>
					<circle cx="75.59" cy="73.09" r="5.14" fill="#fff"/>
					<path d="M84.28,80.12a7.68,7.68,0,0,1-11.83,0,17.74,17.74,0,0,0,5.91.79A24.28,24.28,0,0,0,84,80.12Z" transform="translate(-2.81 -1.39)" fill="#fff"/>
				</g>
				</svg>
			</Link>
		</div>
	);
};

export default Logo;